:root {
  --yellow: #f2b619;
  --white: #ffffff;
  --color-btn: #04a5c2;
  --color-lightgrey: #f9f9f9;
}

.wd-styl {
  width: 170px;
}

.imgcard-styl {
  width: 150px;
  height: 150px;
}
.imgwidth {
  width: 130px;
}
.header-static {
  height: 500px;
}
.separator {
  border: none;
  border-top: 1px solid var(--yellow);
  color: #333;
  overflow: visible;
  text-align: center;
  height: 5px;
}

hr:after {
  background: #fff;
  content: 'ODER';
  padding: 0 4px;
  position: relative;
  top: -13px;
}
.fa-google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background-color: var(--yellow) !important;
  position: static;
  display: inline-block !important;
  border-radius: 2px !important;
  width: 60px !important;
  height: 4px !important;
}
.swiper-pagination {
  margin-top: 20px;
  margin-bottom: 100px;
}
.fa-upload {
  color: var(--yellow);
}
.img-upload {
  display: none;
}
.desc-styl {
  height: 100px;
  /* width: 100%; */
}
/* post upload */
.no-suggestions {
  color: #999;
  padding: 0.4rem;
  font-size: 0.9rem;
}

.suggestions {
  width: 50%;
  overflow-y: auto;
}

/* .suggestions::-webkit-scrollbar {
  width: 0.2rem;
  height: 0rem;
  border-radius: 20px;
}

.suggestions::-webkit-scrollbar-thumb {
  background-color: hsla(218, 100%, 42%, 1);
}

.suggestions li {
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
} */

.suggestion-active,
.suggestions li:hover {
  background-color: var(--color-btn);
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.my-anchor-css-class {
  color: var(--color-btn);
}

/* thema */
.filter-thema-styl {
  /* border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none; */
  border: none;
  background-color: var(--lightgrey);
  color: var(--color-btn);
}

.filter-thema-styl select {
  display: none; /*hide original SELECT element: */
}

.filter-thema-styl select {
  display: none; /*hide original SELECT element: */
}

/*------------------------------- avatar ----------------------------------- */
.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-right: 15px;
}

.avatar {
  position: relative;
  border: 2px solid white;

  border-radius: 100%;
  overflow: hidden;
  width: 30px;
  height: 30px;
  /* margin-right: 110px; */
}

.avatar:not(:last-child) {
  margin-left: -10px;
}

.avatar img {
  width: 100%;
  display: block;
}
.border-search {
  -webkit-appearance: none;
  outline: none;
}
.bg-sidebar {
  background: linear-gradient(180deg, #04a5c2 0%, #4ec7ed 100%);
}
.ulHight {
  height: 77vh;
}
.error-main-text {
  font-size: 150px;
}
.pricing-card {
  height: 100%;
}
.center-div {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
/* media */
@media screen and (max-width: 767px) {
  .mtmob {
    margin-top: 100px;
  }
  .swiper-pagination {
    display: none;
  }
  .suggestions {
    width: 78%;
    /* height: 58%; */
    overflow-y: auto;
  }
  .center-div-mob {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .head-text {
    margin-top: 10px;
    margin-left: 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .res-navbar {
    margin-left: 42px;
  }
  .container-post {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
  .res-navbar {
    margin-left: 20px;
    /* background-color: red; */
  }
  .container-post {
    margin-left: 30px;
  }
}
