.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content i {
  padding: 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content i:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.facebook {
  color: #4267b2;
}

.twitter {
  color: #1da1f2;
}

.whatsapp {
  color: #25d366;
}

@media screen and (max-width: 767px) {
  .margin-mobile {
    margin-top: 10px;
    margin-left: 230px;
  }
}
