@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppinsbold";
  src: local("Poppinsbold"),
    url("../fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "semibold";
  src: local("semibold"),
    url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "bold";
  src: local("bold"), url("../fonts/Poppins-Bold.ttf") format("truetype");
  /* font-weight: bold; */
}

html,
body {
  overflow-x: hidden;
  width: 100%;

  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Poppins", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.input-hidden {
  border: 0;
  outline: 0;
}
.input-hidden:focus {
  outline: none !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #04a5c2;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #04a5c2;
}
::-webkit-scrollbar {
  height: 5px; /* height of horizontal scrollbar ← You're missing this */
  width: 5px; /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}
a {
  cursor: pointer;
}

.input-styl {
  @apply border border-gray-300	 px-3 py-3  text-black bg-white rounded text-xs h-12 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-color-btn w-full ease-linear transition-all duration-150;
}

.btn-styl {
  @apply bg-color-btn text-white text-xs2  font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg  w-full ease-linear transition-all duration-150;
}

.btnthearpy-styl {
  @apply bg-yellow-500 text-white text-xs2  font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg  w-full ease-linear transition-all duration-150;
}
.btn2-styl {
  @apply border border-color-btn bg-white text-color-btn text-xs2 font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg focus:bg-color-btn focus:text-white ease-linear transition-all duration-150;
}

.btn-color {
  @apply border border-color-btn bg-color-btn text-white text-xs2 font-bold uppercase px-3 py-3 rounded shadow hover:shadow-lg tracking-wider focus:bg-color-btn focus:text-white ease-linear transition-all duration-150;
}

.btn-disable {
  @apply border-2 border-gray-100	bg-color-grey text-white text-xs  font-bold uppercase px-3 py-3 rounded mr-1 mb-1 w-full;
}

.btn-social {
  @apply border-2 border-gray-300 bg-white text-gray-900  shadow-sm font-semibold  px-3 py-3 w-full items-center justify-center   rounded outline-none focus:outline-none mr-2  hover:shadow-md ease-linear transition-all duration-150;
}
.mobileview {
  @apply hidden lg:hidden md:hidden xs:block sm:block;
}
.desktopview {
  @apply block lg:block md:block sm:hidden xs:hidden;
}

.desktopviewnoHidden {
  @apply block lg:block md:block;
}

.center-styl {
  @apply flex content-center items-center justify-center;
}

.treatment-view {
  @apply w-full lg:w-8/12 md:w-9/12 xs:w-full sm:w-full;
}
input[type="file"] {
  display: none;
}

sup {
  line-height: 0;

  /* The following rules (or similar) likely come from browser 
   * style and are not needed
   */
  font-size: 0.83em;
  vertical-align: super;
}
/* extra bold */
.bold-styl {
  font-family: Poppinsbold;
}
.semibold {
  font-family: semibold;
}

.textBold {
  font-family: bold;
}
