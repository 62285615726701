:root {
  --yellow: #f2b619;
  --white: #ffffff;
  --primary: #04a5c2;
  --color-lightgrey: #f9f9f9;
}

.wd-styl {
  width: 170px;
}

.imgcard-styl {
  width: 150px;
  height: 150px;
}
.imgwidth {
  width: 130px;
}
.text-color {
  color: var(--primary);
}

@media screen and (min-width: 0) {
  .mtmob {
    margin-top: 100px;
  }
}
.logo-main {
  font-size: 10px;
}
.main-tag {
  font-size: 16px;
  letter-spacing: 0.7px;
  font-family: calc(poppins-semibold, poppins, sans-serif);
}
.main-text {
  font-size: 20px;

  font-weight: 700;
  letter-spacing: 1px;
  line-height: 36px;

  font-family: calc(poppins-semibold, poppins, sans-serif);
}
.heading-main {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  width: 420px;
  letter-spacing: 0.5px;
  font-family: calc(poppins-semibold, poppins, sans-serif);
  text-decoration: none solid rgb(255, 255, 255);
}
.border-bullet {
  border: 6px solid #f3c683;
}
.second-bullet {
  border: 6px solid #8dd1de;
}

.paragraph {
  height: 91px;
  line-height: 30px;
  font-size: 17px;
  word-spacing: 0px;
  text-decoration: none solid rgb(24, 33, 83);
}
.btn-sizes {
  width: 185px;
  height: 50px;
  font-size: 19px;
  letter-spacing: 0.9px;
  margin: 0 4px 0 0;
}
.second-btn {
  width: 241px;
  height: 50px;
  font-size: 19px;
  letter-spacing: 0.9px;
}
.image-sizes {
  height: 97px;
  width: 506px;
  border: 4px solid white;
  margin: 0 4px 0 0;
}

.landing-shadow {
  text-shadow: 0px 3px 6px rgba(128, 0, 0, 0.51);
}
.bg-gradiant {
  background-image: linear-gradient(#06e5f3, #1994b3);
  /* border-radius: 0 0 2% 2%/0 0 2% 2%; */
}
.landing-text-shdw {
  text-shadow: 0px 3px 6px rgba(128, 0, 0, 0.51);
}
.shadow-bg {
  box-shadow: 8px -7px 88px 10px rgba(0, 0, 0, 0.51) inset;
  -webkit-box-shadow: 8px -7px 88px 10px rgba(0, 0, 0, 0.51) inset;
  -moz-box-shadow: 8px -7px 88px 10px rgba(0, 0, 0, 0.51) inset;
}
.back-slate {
  height: 72pc;
}
.footer-height {
  height: 38pc;
}
.img-locations {
  width: 632px;
  height: 491px;
}
.title-koshto {
  font-size: 25px;
  font-weight: 700;
  line-height: 43.2px;
  letter-spacing: 1.2px;
  word-spacing: 0;
}
.Number-style {
  font-size: 50px;
  font-weight: 700;
  line-height: 75px;
}
.name-style {
  font-size: 20px;
  line-height: 28px;
}
.text-style {
  font-size: 17px;
  line-height: 28px;
}
.xpera-heading {
  font-size: 19px;
  line-height: 30.6px;
}
.para-below {
  font-size: 70px;
  line-height: 42px;
  width: 509px;
  height: 102px;
}
.koshto-btn {
  width: 271px;
  height: 48px;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.9;
  background-color: #20a3bb;
}
.jetzt-btn {
  width: 271px;
  height: 48px;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.9;
  background-color: #f2b619;
}
.teilen-btn {
  width: 351px;
  height: 48px;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.9;
  background-color: #20a3bb;
}
.themen-btn {
  width: 371px;
  height: 48px;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 0.9;
  background-color: #20a3bb;
}
.informative-above {
  font-size: 25px;
  line-height: 42px;
  width: 509px;
  height: 110px;
}
.para-above {
  font-size: 25px;
  line-height: 42px;
  width: 509px;
  height: 140px;
}
.Nutzen-above {
  font-size: 25px;
  line-height: 42px;
  width: 509px;
  height: 140px;
}

.Profil-above {
  font-size: 25px;
  line-height: 42px;
  width: 509px;
  height: 110px;
}
.Aless-text {
  font-size: 26px;
  line-height: 42px;
}
.para-medium {
  font-size: 26px;
  line-height: 42px;
  width: 509px;
  height: 160px;
}
.xpera-para {
  width: 509px;
  height: 152px;
  font-size: 17px;
  line-height: 30px;
}
.Haben-para {
  width: 509px;
  height: 152px;
  font-size: 17px;
  line-height: 30px;
}
.img-styling {
  height: 500px;
  width: auto;
}
.themenson-heading {
  font-size: 20px;
  line-height: 30px;
}
.Aless-para {
  font-size: 26px;
  line-height: 45px;
  width: 509px;
  height: 90px;
  font-weight: 700;
  display: block;
}
.heading-5 {
  font-size: 18px;
  line-height: 30px;
}
.sec-img-styling {
  width: 600px;
  height: 533px;
}
.comment-styling {
  width: 660px;
  height: 340px;
  margin-left: 60px;
}
.themenson-para {
  width: 509px;
  height: 90px;
}
.themenson-desc {
  width: 509px;
  height: 152px;
  font-size: 18px;
  line-height: 36px;
}
.para-below {
  font-size: 20px;
  line-height: 42px;
  width: 450px;
  height: 130px;
}
.para-mid {
  font-size: 20px;
  line-height: 42px;
  width: 450px;
  height: 160px;
}
.xpera-btn {
  width: 190px;
  height: 48px;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.9;
  background-color: #f2b619;
}
.footer-text {
  font-size: 17px;
  line-height: 28px;
  width: 390px;
}
.footer-data {
  font-size: 16px;
  line-height: 28px;
}
.text-font {
  font-size: 21px;
}
.footer-number {
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 35px;
  width: 270px;
}
.footer-content {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.9;
  line-height: 18px;
}
.data-placing {
  height: auto;
}
.para-content {
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
  .Profil-above {
    font-size: 19px;
    line-height: 42px;
    width: auto;
    height: 160px;
  }
  .Prominter-text {
    font-size: 15px;
    line-height: 42px;
  }
  .Haben-para {
    width: auto;
    height: 182px;
    font-size: 16px;
    line-height: 30px;
    border: 2px solid black;
  }
  .mbl-img-size {
    height: 100%;
    width: 100%;
  }
  .mobile-btn-margin {
    margin-top: 18px;
  }
  .themen-btn {
    width: 271px;
    height: 55px;
    margin-top: 38px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.9;
    background-color: #20a3bb;
  }
  .informative-above {
    font-size: 19px;
    line-height: 42px;
    width: auto;
    height: 100px;
  }
  .Nutzen-above {
    font-size: 16px;
    line-height: 42px;
    width: auto;
    height: 100px;
  }
  .main-text {
    font-size: 14px;
  }
  .text-font {
    font-size: 15px;
  }
  .btn-sizes {
    width: 185px;
    height: 50px;
    font-size: 19px;
    letter-spacing: 0.9px;
    margin-top: -50px;
  }
  .head-text {
    margin-top: 10px;
    margin-left: 20px;
  }
  .sec-img-styling {
    width: 600px;
    height: 533px;
  }
  .koshto-btn {
    width: 241px;
    height: 48px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.9;

    margin-top: 24px;
  }
  .teilen-btn {
    width: 241px;
    height: 48px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.9;

    margin-top: 24px;
  }
  .para-above {
    font-size: 19px;
    line-height: 42px;
    width: auto;
    height: 160px;
  }
  .button-koshto {
    margin-top: 50px;
  }
  .para-medium {
    font-size: 20px;
    line-height: 42px;
    width: 450px;
    height: 160px;
  }
  /* .comment-styling {
      width: auto;
      height: auto;
      margin-left: 60px;
    } */
  /* .Aless-para {
      width: 409px;
      height: 152px;
      font-size: 20px;
      line-height: 30px;
      justify-content: center;
      display: block;
      margin-left: 70px;
    } */
  .themenson-para {
    width: auto;
    height: 40px;
    font-size: 18px;
    line-height: 30px;

    margin-top: 10px;
  }
  .Aless-para {
    width: auto;
    height: 40px;
    font-size: 18px;
    line-height: 30px;
    border: 2px solid black;
    margin-top: 10px;
  }
  .btn-themenson {
    margin-left: 20px;
  }
  .img-styling {
    height: 350px;
    width: 300px;
  }
  .themenson-heading {
    font-size: 22px;
    line-height: 30px;
    height: 35px;

    display: block;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-top: -90px;
  }
  .xpera-para {
    width: auto;
    height: 182px;
    font-size: 16px;
    line-height: 30px;
  }
  .Haben-para {
    width: auto;
    height: 182px;
    font-size: 16px;
    line-height: 30px;
  }
  .para-content {
    width: auto;
    height: 340px;
    font-size: 16px;
    line-height: 30px;
  }
  .themenson-desc {
    width: 280px;
    height: auto;
    font-size: 16px;

    margin-left: 85px;
    margin-top: -50px;
  }
  .para-below {
    font-size: 18px;
    line-height: 42px;
    width: 409px;
    height: 152px;
  }
  .heading-main {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    width: auto;
    letter-spacing: 0.5px;
    font-family: calc(poppins-semibold, poppins, sans-serif);
    text-decoration: none solid rgb(255, 255, 255);
  }

  .paragraph {
    width: auto;
    height: auto;
    line-height: 30px;
    font-size: 17px;
    word-spacing: 0px;
    text-decoration: none solid rgb(24, 33, 83);
  }
  .img-locations {
    width: auto;
    height: auto;
    display: block;
    justify-content: center;
    align-items: center;
  }

  .landing-mbl-resp {
    justify-content: center;
  }

  .footer-height {
    height: 83pc;
  }
  .mbl-logo {
    width: 140px;
  }
  .landing-mbl-back {
    height: auto;
    justify-content: center;
    border: black;
  }
  .landing-btn {
    width: 260px;
    font-size: 15px;
    margin-top: 8px;
  }
  .landing-buttons {
    display: block;
    margin-top: 100px;
  }

  .shadow-bg {
    box-shadow: 8px -7px 88px 10px rgba(0, 0, 0, 0.51) inset;
    -webkit-box-shadow: 8px -7px 88px 10px rgba(0, 0, 0, 0.51) inset;
    -moz-box-shadow: 8px -7px 88px 10px rgba(0, 0, 0, 0.51) inset;
  }

  .center-styl {
    display: block;
    text-align: center;
    justify-content: center;
  }

  /* .disease-cards {
      height: 10pc;
    } */
  .responsive-2 {
    height: 54pc;
    justify-content: center;
    display: block;
    text-align: center;
  }
  .text-above {
    font-size: 14px;
  }

  .logo-xpera {
    width: 120px;
    height: 112px;
  }
  .img-wrapping {
    width: 250px;
  }
  .curved-img {
    width: 360px;
    height: 320px;
  }
  .title-koshto {
    font-size: 16px;

    font-weight: 700;
    line-height: 35.2px;
    letter-spacing: 0.9px;
    word-spacing: 0;
  }
  .second-title {
    font-size: 18px;

    font-weight: 700;
    line-height: 35.2px;
    letter-spacing: 0.9px;
    word-spacing: 0;
  }
  .mbl-respons {
    display: flex;
    font-size: 15px;
    border: 2px solid black;
    padding: 20px;
  }
  .mbl-cards {
    font-size: 12px;
  }
  .mbl-height {
    width: 350px;
    height: 290px;
    margin-top: 50px;
  }
  .mobile-responsiveness {
    width: auto;
  }
  .mbl-responsive {
    border: 2px solid black;

    padding-left: 6px;
    font-size: 12px;
    margin-bottom: 12px;
  }
  .content-placing {
    margin: auto;
  }
  .mbl-footer {
    height: auto;
  }
  .footer-text {
    line-height: 28px;

    width: 320px;
  }
  .btn-responsivness {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brown-text p {
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 1px;

    background-color: #633c01;
    max-width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brown-text {
    max-width: 320px;
  }
  .bold-para {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 36px;
    background-color: #1d91a6;
    width: 120px;

    font-family: calc(poppins-semibold, poppins, sans-serif);
  }
  .mbl-heading {
    font-size: 12px;
    font-weight: 700;
    /* max-width: 330px; */

    letter-spacing: 0.5px;
  }
  .mbl-heading p {
    font-size: 30px;
    line-height: 45px;

    /* max-width: 320px; */
  }
  .img-locating {
    max-height: auto;
  }

  .backgroud-height {
    height: 45pc;
  }
  .text-below {
    max-width: 300px;
  }
}

/* .btn-responsivness {
    margin-left: 10px;
  } */
.bg-footer {
  background-color: #1fa8c1;
}
.gradiant-bg {
  background-image: linear-gradient(to right, #826055, white);
}

.text-main {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 36px;
  background-color: #633c01;
  width: 610px;
  height: 36px;
  font-family: calc(poppins-semibold, poppins, sans-serif);
}

.main-heading {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  /* width: 700px; */

  letter-spacing: 0.5px;
  font-family: calc(poppins-semibold, poppins, sans-serif);
  text-decoration: none solid rgb(255, 255, 255);
}
.text-below {
  line-height: 30px;
  font-size: 17px;
  word-spacing: 0px;
  text-decoration: none solid rgb(24, 33, 83);
  width: 350px;
}
.description {
  line-height: 30px;
  font-size: 17px;
}
.img-locating {
  height: 42pc;
}
.Packeten-btn {
  width: 300px;

  font-size: 19px;
  letter-spacing: 0.9px;
}
.rating-style {
  font-size: 16px;
  line-height: 25px;
  width: 300px;
  height: 80px;
}
.text-styling {
  font-size: 16px;
  line-height: 25px;
  width: 270px;
  height: 80px;
  padding: auto;
  margin-left: 68px;
}
.state-above {
  font-size: 25px;
  line-height: 42px;
  width: 509px;
  height: 110px;
}
.PROMINENTER-para {
  width: 500px;
  height: 152px;
  font-size: 17px;
  line-height: 30px;
}
.mehr-text {
  font-size: 30px;
  line-height: 54px;
}
.logo-below {
  width: 222px;
  height: 60px;
}
